import React from "react";

import Layout from "../components/Layout";
import Content from "../components/Content";
import SEO from "../components/SEO";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Content>
      <div
        className="main"
        style={{
          alignSelf: "center",
          textAlign: "center",
          marginBottom: "180px",
        }}
      >
        <h1>Page not found</h1>
        <p>
          Unforunately, the page you were looking for was not found. Please
          return to the <Link to="/">homepage</Link> or use the above navigation
          to find the correct page!
        </p>
      </div>
    </Content>
  </Layout>
);

export default NotFoundPage;
